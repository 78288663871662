import React from 'react';

import { Link } from 'gatsby';
import { siteMap } from '../utils/sitemap';

function Hero({ title, description, cta }) {
  return (
    <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              {title}
              <p className="text-base text-gray-200 sm:text-xl lg:text-lg xl:text-xl">
                {description}
              </p>
              {cta && (
                <div className="mt-5 mx-auto sm:flex md:mt-8">
                  <div className="rounded-md shadow">
                    <Link
                      to={siteMap.DEMO.link}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-tb-400 hover:bg-purple-tb-300 md:py-4 md:text-lg md:px-10"
                    >
                      Book a demo
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
