import React from 'react';
import {
  ShareIcon,
  ReceiptTaxIcon,
  ShieldCheckIcon,
  CheckCircleIcon,
  DesktopComputerIcon,
  CubeIcon,
  TerminalIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';

import Feature3 from '../components/feature3';
import CTA from '../components/cta';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Seo from '../components/seo';

const features = [
  {
    name: 'Finance primitives',
    description:
      'Invoices, credit notes, ledgers, counterparties, transactions, and payments. All the base components might need.',
    icon: CubeIcon,
  },
  {
    name: 'Multitenancy',
    description: 'Support for a single or multiple organizations.',
    icon: UserGroupIcon,
  },
  {
    name: 'Security & Privacy',
    description:
      'All our servers are located in Europe and we use the best security standards.',
    icon: ShieldCheckIcon,
  },

  {
    name: 'Developers first',
    description:
      "All our public APIs are designed from the user's perspective.",
    icon: TerminalIcon,
  },
  {
    name: 'Scalability',
    description: 'Up to 100 req/sec per API token. Custom limits on request.',
    icon: CheckCircleIcon,
  },
  {
    name: 'Operations & Tech',
    description:
      'Build a product using our API and let Ops People use the Tresorbase UI.',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Reporting',
    description: 'Generate reports directly from an API call.',
    icon: ReceiptTaxIcon,
  },
  {
    name: 'Bank integrations',
    description:
      'Once you create a bank connection you get transactions, balances, and you can schedule payments.',
    icon: ShareIcon,
  },
];
function IndexPage({ location }) {
  return (
    <Layout>
      <Seo
        title="APIs for Developers"
        description="Finance APIs for developers. Use our APIs to create invoices, credit notes, counterparties, schedule payments, reconcile transactions, and track balances."
        path={location.pathname}
      />
      <Hero
        title={
          <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-100 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            <span className="block">Finance APIs</span>
            <span className="block">to build something</span>
            <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 sm:pb-5">
              your users will love
            </span>
          </h1>
        }
        description="Leverage Tresorbase APIs to create invoices, credit notes, counterparties, schedule payments, reconcile transactions, and track balances. We help you integrate with the banking world in just a few days."
      />
      <Feature3
        title="A faster time-to-market with our APIs."
        description="Build the backoffice of your marketplace or payment operations in a few days instead of months."
        features={features}
      />
      <CTA cta="Ready to start?" />
    </Layout>
  );
}

export default IndexPage;
